.App {
  font-family: sans-serif;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body {
  background-color: azure;
  text-align: center;
}


.header {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: center;
  background-color: #ecf4ff;
 
}

.header img {
  width: 60px; /* Adjust the width as needed */
  height: 60px; /* Adjust the height as needed */
  margin-right: 10px; /* Adjust the margin between img and h1 */
}

.header .title {
  margin: 0; /* Remove default margin on h1 for better alignment */
  
  padding: 10px;
 }

.welcome {
  margin: 5px auto; 
  font-weight: 300px;
}
.welcome span {
  font-weight: bold;
}


.player-status {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 250px;
  position: relative;
  text-align: left;
  transform: translate(30%, -200px);
}
.player-status p>span{
  font-weight: bold;
}
.player-status h2{
  text-align: left;
}
.winner-status {
   width: 20%;
   position: relative;
   background-color: lightblue;
   transform: translate(100px);
  
  
}
.winner-status h1{
  display: inline-block;
 
  /* width: 20%; */
}

.board {
  margin: auto;
  display: grid;
  justify-content: stretch;
  max-width: 500px;
  height: 200px;
  margin-bottom: 5px;
}
.drop-button {
  background-color: black;
}

.cell {
  width: 40px;
  height: 40px;
}


.login-form {
margin-top:42px;
}
form {
  text-align: center;
}
label {
  margin: 10px 0;
  font-size: 18px;
}

input {
  height: 35px;
  width: 200px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5 10px;
  margin:6px;
}

button {
  height: 50px;
  width: 100px;
  font-size: 18px;
  background-color: #2288dd;
  color: white;
  cursor: pointer;
  margin-bottom: 8px;
 
}

button:hover {
  background-color: #0066b3;
}
.success-icon {
  font-size: 80px;
  color: #28a745;
  margin-bottom: 20px;
}